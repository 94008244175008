import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

export const GalleryContainer = styled(Container)`
  max-width: 900px;
  text-align: center;
  margin: 61px auto;

  h1 {
    color: #184554;
  }
`;

export const Heading = styled.h2`
  margin-bottom: 72px;
  font-weight: bold;
`;

export const GalleryWrapper = styled(Row)`
  justify-content: center;
`;

export const TeamMember = styled(Col)`
  text-align: center;
  margin-bottom: 64px;
  img {
    margin: 0 0 0 0;
    width: auto;
    height: 100%;
  }
  h6 {
    text-transform: capitalize;
    letter-spacing: 0;
    font-weight: 700;
  }

  p {
    color: var(--subtle-text-color);
  }
`;

export const ImgWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 164px;
  height: 164px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 16px;
`;
